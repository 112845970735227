import { handleErrorWithSentry, replayIntegration } from "@sentry/sveltekit";
import * as Sentry from '@sentry/sveltekit';

Sentry.init({
  dsn: 'https://fce0385a7e95bdbaa1d879b813f9cb61@sentry.libratech.ai/2',
  tracesSampleRate: 0.1,

  integrations: [replayIntegration({
    maskAllText: false,
    blockAllMedia: false,
  })],

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT || 'local',
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
